import { Center, Heading, Stack } from "@chakra-ui/react"
import type { NextPage } from "next"

import Page from "@shared/ui/page"

const Home: NextPage = () => {
  return (
    <Page title={"Welcome to Web-toolkit"} metaDescription={"The Web-toolkit - little tools for developers."}>
      <Stack>
        <Center>
          <Heading as="h2" size="xl">
            SQ-dev
          </Heading>
        </Center>
      </Stack>
    </Page>
  )
}

export default Home
