import { Box, Heading } from "@chakra-ui/react"
import Head from "next/head"
import { FC, ReactNode } from "react"

interface PageProps {
  title: string
  children: ReactNode
  metaDescription?: string
  metaTitle?: string
}

const Page: FC<PageProps> = ({ title, children, metaTitle, metaDescription }) => {
  return (
    <>
      <Head>
        <title>{metaTitle || title}</title>
        <meta name="description" content={metaDescription || title} />
      </Head>
      <Heading as={"h1"} mb={4} textAlign={"center"}>
        {title}
      </Heading>
      <Box px={5}>{children}</Box>
    </>
  )
}

export default Page
